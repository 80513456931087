import React, { useContext, useState, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { Button, Modal, Spin, Tabs, Table } from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';
import { MultiSelect } from 'react-multi-select-component';
import { apiResponse } from '../../utility/commonMethods';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationBox from '../../components/form-components/confirm-box';
import OverAllAccessTable from '../../components/user-audit/overall-access-table';
import SpecificAccessTable from '../../components/user-audit/specific-access-table';
import SearchModal from '../../components/user-audit/search-modal';
import { getUserDetailsFromOktaToken } from '../../utility/commonMethods';
import * as XLSX from 'xlsx';
import { AppContext } from '../../contexts/app-context';
import { DescriptionType, DropDownValType, PgAdList, TableHeaderType } from '../../components/user-audit/user-audit-interface';
import QuickAddModal from '../../components/user-audit/quick-add-modal';


const UserAudit: React.FC = () => {
  const [workstreamData, setworkstreamData] = useState([]);

  const [AdGroupTableData, setAdGroupTableData] = useState<TableHeaderType[]>([]);
  const [pageName, setPageName] = useState<DropDownValType[]>([]);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [selectedAdGrp, setSelectedAdGrp] = useState<DropDownValType[]>([]);
  const [AdGroupColData, setAdGroupColData] = useState<TableHeaderType[]>([]);
  const [showusertable, setShowuserTable] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [tableData, setTableData] = useState([]);
  const [userTableLoading, setUserTableLoading] = useState(false);
  const [adGroupTableLoading, setAdgroupTableLoading] = useState(false);
  const [userAuditTableHeader, setUserAuditTableHeader] = useState<TableHeaderType[]>([]);
  const [adGrpOption, setAdGrpOption] = useState<DropDownValType[]>([]);
  const [cnfLoader, setCnfLoader] = useState(false);
  const [cnfModal, setCnfModal] = useState(false);
  const [removeUserInfo, setRemoveUserInfo] = useState('');
  const [count, setCount] = useState(1);
  const [initialStateDisabled, setInitialStateDisabled] = useState(true);
  const [customADGrp, setCustomADGrp] = useState([]);
  const [descriptionData, setDescriptionData] = useState([]);
  const [descriptionDataOriginal, setDescriptionDataOriginal] = useState([]);
  const userInfo = getUserDetailsFromOktaToken();
  const { authData } = useContext(AppContext);
  const [originalAuditTableHeader, setOriginalAuditTableHeader] = useState<TableHeaderType[]>([]);
  const [triggerReload, setTriggerReload] = useState<Boolean>(false);
  const [quickAddModalOpen, setQuickAddModalOpen] = useState(false);
  const [subgroupList, setSubgroupList] = useState([]);

  const Audit_dropdown = ['home', 'pipeline', 'supply', 'external', 'finance', 'brand', 'people', 'prioritized_agenda', 'admin', 'curation', 'manufacturing'];
  useEffect(() => {
    let tableData = pageName.flatMap(pageArr =>
      workstreamData.filter((page_name: any) => {
        return page_name.page === pageArr.value;
      }),
    );

    setAdGroupTableData(tableData);
  }, [pageName, workstreamData]);

  useEffect(() => {
    const newColData = originalAuditTableHeader.filter(grpObj => {
      return selectedAdGrp.some(selObj => {
        return grpObj.accessor === 'page' || grpObj.accessor === 'component_hierarchy' || grpObj.accessor === 'description' || selObj.value === grpObj.accessor;
      });
    });
    setAdGroupColData(newColData);
  }, [selectedAdGrp]);

  const rtsHeader = (headerInfo: string) => {
    setGroupName(headerInfo);
    setShowuserTable(true);
    setInitialStateDisabled(false);
    setCount(2);
  };

  useEffect(() => {
    if (groupName !== '') {
      getADGrpUserList();
    }
  }, [groupName]);

  useEffect(() => {
    // getUserAuditTableHeader();
    getUserAuditWorkstreamData();
    getAdGrp_AccPage();
  }, [triggerReload]);

  useEffect(() => {
    let defaultPageName: any = [];
    Audit_dropdown.map((page: any) => {
      defaultPageName.push({
        id: page,
        label: page,
        value: page,
      });
    });
    if (pageName.length === 0) {
      setPageName(defaultPageName);
    }
    if (selectedAdGrp.length === 0) {
      setSelectedAdGrp(adGrpOption);
    }
    if (descriptionDataOriginal.length === 0) getDescription();
  }, [workstreamData, descriptionDataOriginal]);

  const getDescription = async () => {
    //let filteredpages = pageName.map(page => page.value);
    //No need filtered pages, call with pages, so that you won't have to call API everytime
    const payload = {
      pages: 'home,pipeline,supply,external,finance,brand,subgroup,prioritized_agenda,admin,curation,manufacturing',
    };
    try {
      const res = await apiResponse('post', 'get-description-info', [], payload);
      if (res?.data.status === 'SUCCESS') {
        let descriptionData = res.data.data.map((obj: { description: string }) => Object.assign(obj, { description: window.atob(obj.description) }));
        //let descriptionData = res?.data.data;
        setDescriptionData(descriptionData);
        setDescriptionDataOriginal(descriptionData);
      } else {
        console.log('Description API Failed');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserAuditWorkstreamData = async () => {
    setAdgroupTableLoading(true);
    try {
      const res = await apiResponse('get', 'get-user-audit-workstream', [], []);

      if (res?.data.data.workstreamADGroup) {
        let workstreamADGroup = res.data.data.workstreamADGroup.map((obj: { description: string }) =>
          Object.assign(obj, { description: window.atob(obj.description) }),
        );
        setworkstreamData(workstreamADGroup);
        setAdgroupTableLoading(false);
      } else {
        setAdgroupTableLoading(false);
      }
    } catch (error) {
      setAdgroupTableLoading(false);
      console.log(error);
    }
  };

  const getADGrpUserList = async () => {
    const payload = {
      ad_group: groupName,
    };
    try {
      setUserTableLoading(true);
      const res = await apiResponse('post', 'get-ad-group-user-list', [], payload);
      if (res?.data.status === 'SUCCESS') {
        let tableData = res?.data.data;
        setTableData(tableData.filter((obj: any) => obj?.fullname));
        setSubgroupList(tableData.filter((obj: any) => !obj?.fullname));
        setUserTableLoading(false);
      } else {
        setUserTableLoading(false);
      }
    } catch (error) {
      setUserTableLoading(false);
      console.log(error);
    }
  };

  const confirmRemoveUser = async (userData: any) => {
    setCnfModal(true);
    setRemoveUserInfo(userData.loginname);
  };

  const removeUser = async () => {
    setCnfLoader(true);
    const payload = {
      ad_group: groupName,
      user_id: removeUserInfo,
      remove_by: userInfo.userName,
    };
    try {
      const res = await apiResponse('post', 'remove-user', [], payload);
      if (res?.data.status === 'SUCCESS') {
        setCnfModal(false);
        toast.info('User Removal Request has been Submitted, it will take 5 min to reflect.');
        setCnfLoader(false);
      }
    } catch (error) {
      console.log(error);
      setCnfModal(false);
      setCnfLoader(false);
    }
  };

  const downloadExcel = async (data: []) => {
    if (data.length === 0) return;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    const colWidth = [30, 10, 30, 15, 30, 30, 15, 15, 30, 12, 12, 80];
    const cols = [
      'Email',
      'Status',
      'Function',
      'User ID',
      'User Name',
      'Position',
      'Supervisor ID',
      'GCF Level',
      'Supervisor Name',
      'First Visit',
      'Last Visit',
      'Profile Link',
    ];

    const excelStyle = {
      tableHeader: {
        font: {
          bold: true,
        },
        fill: {
          fgColor: { rgb: 'DDDDDD' },
        },
      },
    };

    //XLSX.utils.sheet_add_aoa(ws, [cols], { origin: 'A1' });

    for (let i = 0; i < cols.length; i++) {
      const cellName = XLSX.utils.encode_cell({ r: 0, c: i }); // where s = starting indx, c = column & r is for row
      ws[cellName].v = cols[i];
      ws[cellName].s = {
        font: {
          name: 'arial',
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: '1',
        },
        border: {
          right: {
            style: 'thin',
            color: '000000',
          },
          left: {
            style: 'thin',
            color: '000000',
          },
        },
      };
    }

    ws['!cols'] = colWidth.map((width: number) => ({ wch: width }));
    XLSX.utils.book_append_sheet(wb, ws, groupName.substring(0, 31));
    XLSX.writeFile(wb, 'UserList_' + new Date().getTime() + '.xlsx', {
      bookType: 'xlsx',
      type: 'buffer',
      cellStyles: true,
    });
  };

  const getAdGrp_AccPage = async () => {
    try {
      setUserTableLoading(true);
      const res = await apiResponse('get', 'get-adgrp-acc-to-page', [], []);
      if (res?.data.status === 'SUCCESS') {
        let tableData = res?.data.data;
        setCustomADGrp(tableData);
        setUserTableLoading(false);
        let header = [
          {
            Header: 'Page',
            accessor: 'page',
          },
          {
            Header: 'Component Hierarchy',
            accessor: 'component_hierarchy',
          },
          {
            Header: 'Component Description',
            accessor: 'description',
          },
        ];

        let options = [
          {
            id: 0,
            value: '',
            label: '',
          },
        ];
        tableData.map((name: any) => {
          name.ad_list.map((adGrp: any, index: any) => {
            header.push({
              Header: adGrp,
              accessor: adGrp,
            });
            options.push({
              id: index,
              value: adGrp,
              label: adGrp,
            });
          });
        });

        // for removing duplicate ad-group
        options.shift();
        const ids = header.map(({ Header }) => Header);
        const uniqueHeader = header.filter(({ Header }, index) => !ids.includes(Header, index + 1));

        const optionIds = options.map(({ value }) => value);
        const uniqueOptions = options.filter(({ value }, index) => !optionIds.includes(value, index + 1));

        setAdGrpOption(uniqueOptions);
        setSelectedAdGrp(uniqueOptions);
        setUserAuditTableHeader(uniqueHeader);
        setOriginalAuditTableHeader(uniqueHeader);
      } else {
        setUserTableLoading(false);
      }
    } catch (error) {
      setUserTableLoading(false);
      console.log(error);
    }
  };

  const handlePageChange = async (pageName: any) => {
    pageName.sort((a: { id: string }, b: { id: string }) => Audit_dropdown.indexOf(a.id) - Audit_dropdown.indexOf(b.id));
    setPageName(pageName);
    let filteredGrpList: string[] = customADGrp
      .filter((obj: PgAdList) => pageName.map((pgName: DropDownValType) => pgName.value).indexOf(obj.page) !== -1)
      .map((grp: PgAdList) => grp.ad_list)
      .flat();

    let updateAdgroup: any = [];
    pageName.map((pageArr: any) =>
      customADGrp.map((page_name: any) => {
        if (page_name.page === pageArr.value) {
          return updateAdgroup.push(page_name.ad_list);
        }
      }),
    );

    let options = [
      {
        id: 0,
        value: '',
        label: '',
      },
    ];
    updateAdgroup.flatMap((name: any, index: any) => {
      name.map((adgrp: any) => {
        options.push({
          id: index,
          value: adgrp,
          label: adgrp,
        });
      });
    });

    // for removing duplicate ad-group
    options.shift();
    const optionIds = options.map(({ value }) => value);
    const uniqueOptions = options.filter(({ value }, index) => !optionIds.includes(value, index + 1));

    setAdGrpOption(uniqueOptions);
    setSelectedAdGrp(uniqueOptions);

    setDescriptionData(descriptionDataOriginal.filter((obj: DescriptionType) => filteredGrpList.indexOf(obj.ad_group) !== -1));
  };

  const handleGroupchange = (grp: DropDownValType[]) => {
    //let customList = customADGrp.filter((obj: any) => pageName.map(pgName => pgName.value).indexOf(obj.page) !== -1);
    setUserAuditTableHeader(originalAuditTableHeader.filter((obj: TableHeaderType) => grp.map(grpInfo => grpInfo.value).indexOf(obj.Header) !== -1));
    setDescriptionData(descriptionDataOriginal.filter((obj: DescriptionType) => grp.map(grpInfo => grpInfo.value).indexOf(obj.ad_group) !== -1));
    setSelectedAdGrp(grp.map((grp, index) => Object.assign({}, { id: index, label: grp.value, value: grp.value })));
  };

  return (
    <>
      <div className="d-flex audit-filters">
        <div className="page-filter">
          <label className="form-label">
            Page <sup className="sup-star">*</sup>
          </label>
          <MultiSelect
            options={Audit_dropdown.map((item: any) => {
              return {
                id: item,
                label: item,
                value: item,
              };
            })}
            className="multiselect"
            value={pageName}
            onChange={(page: any) => handlePageChange(page)}
            labelledBy="Select"
          />
        </div>
        <div className="ad-grp-filter">
          <label className="form-label">Ad-Group</label>
          <MultiSelect
            options={adGrpOption}
            value={selectedAdGrp}
            className="multiselect"
            onChange={(grp: DropDownValType[]) => handleGroupchange(grp)}
            labelledBy="Select"
          />
        </div>

        {!authData.adminAuthorization.userAuditView && (
          <div className="filters__search">
            <Button type="primary" className="" onClick={() => setQuickAddModalOpen(true)} text="Quick Add / Remove" />
            <Button type="primary" className="ml-1" onClick={() => setSearchModalOpen(true)} text="Search By User" />
          </div>
        )}
      </div>

      <Collapsible trigger="Overall Access Details" open={count === 1} handleTriggerClick={() => setCount(1)} className="">
        <div className="adgrp-note">
          <p>* Click on Ad-group for information</p>
        </div>
        {adGroupTableLoading ? (
          <div className="adm-loader-container">
            <Spin />
          </div>
        ) : (
          <div className="table-container audit-container">
            {pageName.length > 0 ? (
              <OverAllAccessTable
                adGroupColData={AdGroupColData}
                adGroupTableData={AdGroupTableData}
                rtsHeader={rtsHeader}
                userAuditTableHeader={userAuditTableHeader}
                descriptionData={descriptionData}
                triggerComponentDescriptionUpdate={triggerReload}
                setDescriptionData={(val: []) => setDescriptionDataOriginal(val)}
                setTriggerComponetUpdate={(val: Boolean) => setTriggerReload(val)}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </Collapsible>

      <Collapsible
        trigger="Specific AD Group details"
        open={count === 2}
        handleTriggerClick={() => setCount(2)}
        className=""
        triggerDisabled={initialStateDisabled}
      >
        {showusertable &&
          (userTableLoading ? (
            <div className="adm-loader-container">
              <Spin />
            </div>
          ) : subgroupList.length > 0 ? (
            <Tabs
              items={[
                {
                  key: 'user-list',
                  label: 'User List',
                  children: (
                    <div>
                      <p className="group-info">
                        <span className="group__name">Group Name: {groupName}</span>
                        <span className="group__user">No. of User: {tableData.length}</span>
                        <div className="export_button">
                          <Button type="primary" className="" onClick={() => downloadExcel(tableData as [])} text="Export to Excel" />
                        </div>
                      </p>
                      <div className="table-container audit-container">
                        <SpecificAccessTable tableData={tableData} confirmRemoveUser={confirmRemoveUser} />
                      </div>
                    </div>
                  ),
                },
                {
                  key: 'sub-group-list',
                  label: 'Sub-group List',
                  children: (
                    <div>
                      <p className="group-info">
                        <span className="group__name">Group Name: {groupName}</span>
                      </p>
                      <div className="table-container audit-container">
                        <Table
                          columns={[
                            {
                              dataIndex: 'loginname',
                              key: 'loginname',
                              title: 'AD-Group'
                            },
                          ]}
                          dataSource={subgroupList}
                          style={{
                            width: '100%',
                          }}
                          scroll={{
                            y: 400
                          }}
                        />
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <div>
              <p className="group-info">
                <p className="group__name">Group Name: {groupName}</p>
                <p className="group__user">No. of User: {tableData.length}</p>
                <div className="export_button">
                  <Button type="primary" className="" onClick={() => downloadExcel(tableData as [])} text="Export to Excel" />
                </div>
              </p>
              <div className="table-container audit-container">
                <SpecificAccessTable tableData={tableData} confirmRemoveUser={confirmRemoveUser} />
              </div>
            </div>
          ))}
      </Collapsible>

      <SearchModal opened={searchModalOpen} searchModalClosed={() => setSearchModalOpen(false)} />
      <QuickAddModal opened={quickAddModalOpen} quickAddModalClosed={() => setQuickAddModalOpen(false)} />

      <Modal className="cnf-modal" open={cnfModal} afterClose={() => setCnfModal(false)}>
        <Modal.Title title={'Remove User'} className="cnf-title" />
        {cnfLoader ? (
          <div className="adm-loader-container">
            <Spin />
          </div>
        ) : (
          <ConfirmationBox userInfo={removeUserInfo} groupName={groupName} onConfirm={removeUser} onCancel={() => setCnfModal(false)} />
        )}
      </Modal>
      <ToastContainer
        className="toast-position"
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default UserAudit;
