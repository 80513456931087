import { useEffect, useState } from 'react';
import { ActivityLog } from '@gitlab-rtsensing-activity-log/activity-logs-feature';
import {
	Flex,
	Title,
} from '@opsdti-global-component-library/amgen-design-system';
import { fetchActivityLogDropdowns } from '../../../services/activity-log-dropdown-options-data';
import { fetchActivityLogSummaryData } from '../../../services/activity-log-summary-data';
import { fetchActivityLogTimelineData } from '../../../services/activity-log-timeline-data';
import { snakeToCamel } from '../../../utils/snake-to-camel';
import './index.scss';

const defaultDynamiPAActivityLogFilters = {
	workstream: 'Prioritized Agenda',
	filter_list: [
		{
			filter_key: 'item_id',
			filter_value: 'All',
		},
		{
			filter_key: 'status',
			filter_value: 'All',
		},
		{
			filter_key: 'timeline',
			filter_value: 'Last 60 Days',
		},
	],
};

const defaultF12QActivityLogFilters = {
	workstream: 'Prioritized Agenda',
	filter_list: [
		{
			filter_key: 'item_id',
			filter_value: 'All',
		},
		{
			filter_key: 'key',
			filter_value: 'All',
		},
		{
			filter_key: 'timeline',
			filter_value: 'Last 60 Days',
		},
	],
};

const ActivityLogPage = () => {
	const [dynamicPAaiSummary, setDynamicPAaiSummary] = useState<string>('');
	const [f12qAiSummary, setF12QaiSummary] = useState<string>('');
	const [dynamicPAdropdownOptions, setDynamicPADropdownOptions] = useState<any>(
		{}
	);
	const [f12qDropdownOptions, setF12QdropdownOptions] = useState<any>({});
	const [dynamicPAactivityLogTimeline, setDynamicPAactivityLogTimeline] =
		useState<any>([]);
	const [f12qActivityLogTimeline, setF12QactivityLogTimeline] = useState<any>(
		[]
	);
	const [dynamicPAfiltersSelection, setDynamicPAfiltersSelection] =
		useState<any>(defaultDynamiPAActivityLogFilters);
	const [f12qFiltersSelection, setF12QfiltersSelection] = useState<any>(
		defaultF12QActivityLogFilters
	);
	const [dynamicPAisFetching, setDynamicPAisFetching] =
		useState<boolean>(false);
	const [f12qIsFetching, seF12QIsFetching] = useState<boolean>(false);
	const [isDynamicPAFirstLoad, setIsDynamicPAFirstLoad] =
		useState<boolean>(true);
	const [isF12QFirstLoad, setIsF12QFirstLoad] = useState<boolean>(true);

	const onDynamicPAFilterValueChange = (obj) => {
		setDynamicPAfiltersSelection((prevPayload) => ({
			...prevPayload,
			filter_list: obj.map((item) => ({
				filter_key: item.filterKey,
				filter_value: item.filterValue,
			})),
		}));
	};

	const onF12QFilterValueChange = (obj) => {
		setF12QfiltersSelection((prevPayload) => ({
			...prevPayload,
			filter_list: obj.map((item) => ({
				filter_key: item.filterKey,
				filter_value: item.filterValue,
			})),
		}));
	};

	// Data populate with default parameters
	useEffect(() => {
		Promise.all([
			fetchActivityLogDropdowns({
				workstream: 'Prioritized Agenda',
				activity_type: 'DynamicPA',
			}),
			fetchActivityLogDropdowns({
				workstream: 'Prioritized Agenda',
				activity_type: 'F12Q',
			}),
		]).then((responses) => {
			const [dynamicPAdropdownOptionsResponse, f12qDropdownOptionsResponse] =
				responses;
			const camelDynamicPAdropdownOptionsResponse = snakeToCamel(
				dynamicPAdropdownOptionsResponse
			);
			const camelF12QdropdownOptionsResponse = snakeToCamel(
				f12qDropdownOptionsResponse
			);
			setDynamicPADropdownOptions(camelDynamicPAdropdownOptionsResponse);
			setF12QdropdownOptions(camelF12QdropdownOptionsResponse);
		});
	}, []);

	// Data populate with updated parameters
	useEffect(() => {
		setDynamicPAisFetching(true);
		Promise.all([
			fetchActivityLogSummaryData({
				...dynamicPAfiltersSelection,
				activity_type: 'DynamicPA',
			}),
			fetchActivityLogTimelineData({
				...dynamicPAfiltersSelection,
				activity_type: 'DynamicPA',
			}),
		])
			.then((responses) => {
				const [dynamicPAaiSummaryResponse, dynamicPAtimelineResponse] =
					responses;
				const cameldynamicPAtimelineResponse = dynamicPAtimelineResponse.map(
					(item) => {
						return snakeToCamel(item);
					}
				);

				setDynamicPAaiSummary(
					(dynamicPAaiSummaryResponse ?? '') as React.SetStateAction<string>
				);
				setDynamicPAactivityLogTimeline(
					cameldynamicPAtimelineResponse &&
						cameldynamicPAtimelineResponse.length > 0
						? cameldynamicPAtimelineResponse
						: []
				);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setDynamicPAisFetching(false);
				setIsDynamicPAFirstLoad(false);
			});
	}, [dynamicPAfiltersSelection]);

	useEffect(() => {
		seF12QIsFetching(true);
		Promise.all([
			fetchActivityLogSummaryData({
				...f12qFiltersSelection,
				activity_type: 'F12Q',
			}),
			fetchActivityLogTimelineData({
				...f12qFiltersSelection,
				activity_type: 'F12Q',
			}),
		])
			.then((responses) => {
				const [f12qAiSummaryResponse, f12qTimelineResponse] = responses;

				const camelF12QtimelineResponse = f12qTimelineResponse.map((item) => {
					return snakeToCamel(item);
				});
				setF12QaiSummary(
					(f12qAiSummaryResponse ?? '') as React.SetStateAction<string>
				);
				setF12QactivityLogTimeline(
					camelF12QtimelineResponse && camelF12QtimelineResponse.length > 0
						? camelF12QtimelineResponse
						: []
				);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				seF12QIsFetching(false);
				setIsF12QFirstLoad(false);
			});
	}, [f12qFiltersSelection]);

	return (
		<Flex vertical gap={20}>
			<div>
				<Title level={1}>Dynamic PA</Title>
				<ActivityLog
					title="Recent Updates"
					workstream="Prioritized Agenda"
					type="DynamicPA"
					timelineSize={3}
					hideAISummary={false}
					aiSummary={dynamicPAaiSummary}
					timelineData={dynamicPAactivityLogTimeline}
					dropdownMenuOptions={dynamicPAdropdownOptions}
					onFilterChange={(obj) => onDynamicPAFilterValueChange(obj)}
					loading={dynamicPAisFetching && isDynamicPAFirstLoad}
					drawerLoading={dynamicPAisFetching}
				/>
			</div>
			<div>
				<Title level={1}>F12Q</Title>
				<ActivityLog
					title="Recent Updates"
					workstream="Prioritized Agenda"
					type="F12Q"
					timelineSize={3}
					hideAISummary={false}
					aiSummary={f12qAiSummary}
					timelineData={f12qActivityLogTimeline}
					dropdownMenuOptions={f12qDropdownOptions}
					onFilterChange={(obj) => onF12QFilterValueChange(obj)}
					loading={f12qIsFetching && isF12QFirstLoad}
					drawerLoading={f12qIsFetching}
				/>
			</div>
		</Flex>
	);
};

export default ActivityLogPage;
