import React, { useState, useMemo, useEffect, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Button, Segmented, InfoIcon } from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';
import { apiResponse } from '../../utility/commonMethods';
import { toast } from 'react-toastify';
import { columnDefs, vpMappingDiffCol } from './peopleTableHeader';
import { ColDef } from '@ag-grid-community/core';
import { getUserDetailsFromOktaToken, rowSpanning } from '../../utility/commonMethods';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { adminConstants } from '../../utility/constants';
import { AppContext } from '../../contexts/app-context';

const EditPeopleMapping: React.FC = () => {
  const [peopleHeader, setPeopleHeader]: any = useState([]);
  const [viewMode, setViewMode] = useState('View');
  const [rowData, setRowData] = useState<any>([]);
  const [peopleData, setPeopleData] = useState([]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [vpMappingDiffOriginal, setVpMappingDiffOriginal] = useState<any>([]);
  const [vpMappingDifference, setVpMappingDifference] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [disabledRowIds, setDisabledRowIds] = useState<Set<string>>(new Set());
  const [loginName, setLoginName] = useState('');
  const { constInfo } = useContext(AppContext);
  const userInfo = getUserDetailsFromOktaToken();
  const isViewMode = constInfo.get(adminConstants.ADMIN_VP_MAPPING_VIEW_MODE) === 'Y'; //to check if the user can view or edit the data

  const defaultColDef: any = useMemo<ColDef>(() => {
    return {
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      sortable: viewMode === 'Edit' ? false : true,
      wrapText: false,
      filter: viewMode === 'Edit' ? false : true,
      editable: viewMode === 'Edit',
      filterParams: { maxNumConditions: 1 },
      rowSelection: (params: any) => isFirstColumn(params),
      resizable: true,
    };
  }, [viewMode]);

  /*     const defaultDiffColDef: any = useMemo<ColDef>(() => {
        return {
            initialWidth: 280,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            sortable: true,
            wrapText: false,
            filter: true,
            filterParams: { maxNumConditions: 1 },
            resizable: true,
        };
    }, []); */

  function isFirstColumn(params: any) {
    if (viewMode === 'Edit') {
      var displayedColumns = params.api.columnModel.displayedColumns;
      var thisIsFirstColumn = displayedColumns[0] === params.column;
      return thisIsFirstColumn;
    }
    return false;
  }

  useEffect(() => {
    if (viewMode === 'View' && gridApi !== null) {
      refreshTableData();
    }
    const login_name_col = {
      headerName: 'Login Name',
      field: 'login_name',
      lockPosition: 'left',
      cellClass: 'locked-col',
      tooltipField: 'login_name',
      editable: (params: any) => params.data.state === 'create',
      cellRenderer: (params: any) => {
        //if(viewMode === 'View' && !isViewMode && params.data.change_flag) {
        if (params.data.change_flag) {
          return (
            <span className="grp-clickable" onClick={() => setLoginName(params.value)}>
              {params.value}
              <sup>*</sup>
            </span>
          );
        } else {
          return params.value;
        }
      },
    };
    setPeopleHeader([login_name_col, ...columnDefs]);
  }, [viewMode]);

  useEffect(() => {
    getPeopleMappingData();
    getPeopleMappingDifference();
  }, []);

  useEffect(() => {
    if (loginName !== '') {
      setVpMappingDifference(vpMappingDiffOriginal.filter((item: any) => item.login_name === loginName));
    }
  }, [loginName]);

  const getPeopleMappingDifference = async () => {
    try {
      const res = await apiResponse('get', 'get-vp-mapping-difference', [], {});
      if (res?.data.status === 'SUCCESS') {
        setVpMappingDiffOriginal(rowSpanning(res.data.data, 'login_name'));
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
  };

  const addNewRow = () => {
    const newRow = { created_by: userInfo?.email.split('@')[0], created_on: new Date().toISOString(), state: 'create' };
    gridApi.applyTransaction({ add: [newRow], addIndex: 0 });

    // setRowData([newRow, ...rowData]);
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    if (gridApi !== null) {
      const selectedRows = gridApi.getSelectedRows().map((row: any) => {
        if (row.state === 'create' || row.state === 'delete') {
          return row;
        }
        row.state = 'update';
        row.updated_by = userInfo?.email.split('@')[0];
        row.updated_on = new Date().toISOString();
        return row;
      });
      setSelectedRows(selectedRows);
    }
  };

  const deleteSelectedRows = (params: any) => {
    if (gridApi !== null) {
      const selectedRow = gridApi.getSelectedRows().map((row: any) => {
        if (row.state === 'create') {
          gridApi.applyTransaction({ remove: [row] });
        } else {
          row.state = 'delete';
          return row;
        }
      });
      setSelectedRows(selectedRow);
      const selectedIds = selectedRow.map((node: any) => {
        if (node !== undefined) {
          return node.login_name;
        }
      });
      setDisabledRowIds((prev: any) => new Set([...prev, ...selectedIds]));
    }
  };

  useEffect(() => {
    if (gridApi !== null) {
      gridApi.redrawRows(gridApi.getSelectedNodes());
    }
  }, [disabledRowIds]);

  const getPeopleMappingData = async () => {
    try {
      const viewMode = !isViewMode ? ['edit'] : ['view'];
      const res = await apiResponse('get', 'get-people-mapping', [viewMode], {});
      if (res?.data.status === 'SUCCESS') {
        if (res.data.data.length > 0) {
          const rowData = res.data.data.map((item: any) => {
            item.state = '';
            return item;
          });
          setPeopleData(JSON.parse(JSON.stringify(rowData)));
          setRowData(JSON.parse(JSON.stringify(rowData)));
        } else {
          toast.error('People VP Mapping Data not available!!');
        }
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
  };

  const updateAddPeopleMappingData = async () => {
    if (selectedRows.filter((row: any) => row.state !== 'delete').length === 0) {
      return;
    }
    const payload = {
      people_list: selectedRows.filter((row: any) => row.state !== 'delete'),
    };
    try {
      const res = await apiResponse('post', 'update-add-people-mapping', [], payload);
      if (res?.data.status === 'SUCCESS') {
        toast.success('People VP Mapping Data Updated/ Added Successfully!!');
        getPeopleMappingData();
      } else {
        toast.error('Failed to Add People VP Mapping Data. Please fill all details');
      }
      setSelectedRows([]);
      gridApi.deselectAll();
      gridApi.setFilterModel(null);
      //gridApi.setDefaultColDef({ ...defaultColDef, sort: null });
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
  };

  const handleCellEditingStarted = (event: any) => {
    const rowIndex = event.rowIndex;
    const node = event.node;
    if (rowData[rowIndex]?.state === 'create') {
      return;
    }
    if (selectedRows.length > 0 && selectedRows[rowIndex]) {
      setSelectedRows((prevSelectedRows: any) => {
        const newSelectedRows = [...prevSelectedRows];
        newSelectedRows[rowIndex].state = 'update';
        return newSelectedRows;
      });
    } else {
      const newSelectedRows: any = [...rowData];
      newSelectedRows[rowIndex].state = 'update';
      setSelectedRows(newSelectedRows);
    }
    node.setSelected(true);
  };

  const deletePeopleMappingData = async () => {
    if (selectedRows.filter((row: any) => row.state === 'delete').length === 0) {
      return;
    }
    const payload = {
      people_list: selectedRows.filter((row: any) => row.state === 'delete'),
    };
    try {
      const res = await apiResponse('post', 'update-remove-people-mapping', [], payload);
      if (res?.data.status === 'SUCCESS') {
        toast.success('People VP Mapping Data Deleted Successfully!!');
        getPeopleMappingData();
        gridApi.setFilterModel(null);
        //gridApi.setDefaultColDef({ ...defaultColDef, sort: null });
      } else {
        toast.error('Failed to delete People VP Mapping Data');
      }
      setDisabledRowIds(new Set());
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
  };

  const refreshTableData = () => {
    setRowData(JSON.parse(JSON.stringify(peopleData))); // to refresh the table data
    setSelectedRows([]);
    setDisabledRowIds(new Set());
    gridApi.setFilterModel(null);
    //gridApi.setDefaultColDef({ ...defaultColDef, sort: null });
    gridApi.deselectAll();
  };

  const submitData = () => {
    gridApi.stopEditing();
    updateAddPeopleMappingData();
    deletePeopleMappingData();
  };

  const getRowClass = (params: any): string | undefined => {
    if (disabledRowIds.size > 0) {
      if (disabledRowIds.has(params.data.login_name) && params.data.state !== 'create') {
        return 'disabled-row';
      }
    }
    return undefined;
  };

  const publishVPMapping = async (env: string) => {
    try {
      const res = await apiResponse('post', 'post-people-mapping', [env], {});
      if (res?.status === 200) {
        toast.success('People VP Mapping Data Published Successfully!!');
      } else {
        toast.error('Failed to publish People VP Mapping Data');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
  };

  var current_Login_name = '';
  let columnChecks = ['login_name', 'diff_type'];

  return (
    <>
      <div className="mapping-top-section">
        <div className="mapping-title">VP Mapping Table</div>
        <>{!isViewMode && <Segmented options={['View', 'Edit']} value={viewMode} onChange={value => setViewMode(value.toString())} />}</>
        <div className="publish-btns d-flex">
          <Button text="Publish to Non-Prod" type="secondary" className="publish-btn" onClick={() => publishVPMapping('stg')}></Button>
          <div className="btn-separator">|</div>
          <Button text="Publish to Prod" type="primary" className="publish-btn" onClick={() => publishVPMapping('prd')}></Button>
        </div>
      </div>
      <div className="mapping-table-container">
        {viewMode === 'Edit' && (
          <div className="mapping-button-container">
            <Button text="add" type="secondary" className="edit-btn" onClick={addNewRow}></Button>
            <Button text="delete" type="secondary" className="edit-btn" onClick={deleteSelectedRows}></Button>
            <Button text="reset" type="secondary" className="edit-btn" onClick={refreshTableData}></Button>
            <Button text="Submit" type="primary" onClick={submitData} className="submit-btn"></Button>
          </div>
        )}
        {/*</div><div className="ag-theme-alpine" style={{ height: loginName !== '' && viewMode !== 'Edit' ? '50vh' : '70vh', width: 'auto' }}>*/}
        <div className="ag-theme-alpine" style={{ height: '50vh', width: 'auto' }}>
          <AgGridReact
            columnDefs={peopleHeader}
            rowData={rowData}
            defaultColDef={defaultColDef}
            editType={'fullRow'}
            rowSelection={'multiple'}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            getRowClass={getRowClass}
            onCellEditingStarted={handleCellEditingStarted}
            suppressCellFocus={true}
            overlayNoRowsTemplate='<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
          />
        </div>
      </div>
      {/*{loginName !== '' && viewMode === 'View' && !isViewMode && (*/}
      {loginName !== '' && (
        <div className="mapping-table-container">
          <div className="mapping-title-diff">VP Mapping Difference Table</div>
          <div className="mapping-diff-container">
            <div className="vp-mapping-table-container">
              <>
                {vpMappingDifference.length === 0 ? (
                  <p className="table-msg">"No Data Available"</p>
                ) : (
                  <table className="vp-mapping-table">
                    <thead>
                      <tr>
                        {vpMappingDiffCol.map((data: any, indx: any) => {
                          return <th key={indx}>{data.headerName}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {vpMappingDifference.map((data: any, indx: any) => {
                        return (
                          <tr key={indx}>
                            {vpMappingDiffCol.map((colData: any, colIndx: any) => {
                              if (columnChecks.indexOf(colData.field) > -1) {
                                if (data.row_span_count > 1) {
                                  current_Login_name = data.login_name;
                                  return (
                                    <td key={indx + colIndx} rowSpan={data.row_span_count}>
                                      {data[colData.field]}
                                    </td>
                                  );
                                } else if (data.row_span_count === 1 && current_Login_name === data.login_name) {
                                  return <React.Fragment key={indx + colIndx}></React.Fragment>;
                                } else {
                                  return <td key={indx + colIndx}>{data[colData.field]}</td>;
                                }
                              } else {
                                return <td key={indx + colIndx}>{data[colData.field]}</td>;
                              }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditPeopleMapping;
